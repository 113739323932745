<template>
  <ContainerList
    ref="container"
    :show-page="false">
    <div slot="header">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <Select v-model="filterData.orderNoType" size="large" style="margin-right: 20px;">
              <Option v-for="item in orderTypeOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <Input v-model="filterData.orderNo" suffix="ios-search" size="large" maxlength="128"  :placeholder="`${ orderNoType[filterData.orderNoType] }`" />
          </div>
          <div class="itembtn">
            <Button class="searchBt" @click="getDataByFilterBtn" style="margin-left:20px;">查询</Button>
          </div>
        </div>
      </div>
    </div>
    <Table row-key
      min-width="1000px"
      class="tab1"
      :columns="tabActive == 0 ? orderColumns : orderColumns1"
      :data="orderData"
      :loading="isLoading">
      <template slot="transferAmountMoney" slot-scope="{ row }">
        <p>{{ (row.transferAmount / 100) | formatMoney }}</p>
        <p>{{(row.transferFee / 100) | formatMoney}}</p>
      </template>
      <template slot="transferDebitAmount" slot-scope="{ row }">
        <p>{{ (row.transferDebitAmount / 100) | formatMoney }}</p>
      </template>
      <template slot="orderStatus" slot-scope="{ row }">
        <p>{{ orderStatus[row.orderStatus] }}</p>
      </template>
      <template slot="action" slot-scope="{ row }">
        <a v-auth="'BASIC:BALANCE:TRANSFER/DETAIL'" class="lianjie" @click="toDetail(row)">详情</a>
        <a v-auth="'BASIC:BALANCE:TRANSFER/SYNC'" class="lianjie" @click="sync(row)">同步</a>
      </template>
    </Table>
    <!-- 明细 -->
    <Modal title="转账明细" v-model="carryOverShow" closable width="980px" :close-on-click-modal="false">
        <div style="min-height:auto;padding: 0px 20px 20px;box-sizing: border-box;">
          <Table row-key
            :columns="carryOverColumns"
            :data="carryOverData.batchTransferDetailList"
            :loading="isLoading">
            <template slot="transfer_amount" slot-scope="{ row }">
              <p>{{ (row.transfer_amount / 100) | formatMoney }}</p>
            </template>
            <template slot="transfer_debit_amount" slot-scope="{ row }">
              <p>{{ (row.transfer_debit_amount / 100) | formatMoney }}</p>
            </template>
            <template slot="transfer_fee" slot-scope="{ row }">
              <p>{{ (row.transfer_fee / 100) | formatMoney }}</p>
            </template>
          </Table>
        </div>
        <span slot="footer" class="dialog-footer"></span>
    </Modal>
  </ContainerList>
</template>
<script>
import {
  getTransferorderSearch,
  setTransferSync,
  setTransferBatchSync,getTransferBatchOrderSearch
} from "@/api/basic/balance.js";
import ContainerList from '@/components/ContainerList/index'
import tableMixin from '@/mixins/tableMixin'
import { mapState, mapMutations } from "vuex";
export default {
  components: { ContainerList },
  mixins: [ tableMixin ],
  data() {
    return {
      orderTypeOptions: [
        { label: '转账订单号', value: 'mchOrderNo' },
        { label: '转账流水号', value: 'plfOrderNo' },
        { label: '三方转账订单号', value: 'upsOrderNo' },
        { label: '批次转账订单号', value: 'mchBatchNo' },
        { label: '批次转账流水号', value: 'plfBatchNo' },
        { label: '三方批次转账订单号', value: 'upsBatchNo' }
      ],
      orderStatus: {
        PROCESSING: "处理中",
        FAIL: "交易失败",
        SUCCESS: "交易成功",
      },
      orderNoType: {
        mchOrderNo: '转账订单号',
        plfOrderNo: '转账流水号',
        upsOrderNo: '三方转账订单号',
        mchBatchNo: '批次转账订单号',
        plfBatchNo: '批次转账流水号',
        upsBatchNo: '三方批次转账订单号',        
      },
      orderStatus: {
        PROCESSING: '处理中',
        FAIL: '交易失败',
        SUCCESS: '交易成功',
      },

      filterData: {
        orderNoType: 'mchOrderNo',
        orderNo: '',
        pageNo: 1,
        pageSize: 10
      },
      totalNum: 0,
      tableLoading: false,
      orderColumns: [
        {
          key: 'createTime',
          align: 'center',
          minWidth: 170,
          renderHeader: (h) => {
            return h('div', [
              h('p', '创建时间'), 
              h('p', '交易时间'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.createTime }`),
              h('p', `${ row.tradeTime }`)
            ])
          }
        },
        {
          key: 'sourceMchName',
          align: 'center',
          minWidth: 210,
          renderHeader: (h) => {
            return h('div', [
              h('p', '发起方名称'), 
              h('p', '发起方编号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.sourceMchName }`),
              h('p', `${ row.sourceMchNo }`)
            ])
          }
        },
        {
          key: 'targetMchModel',
          align: 'center',
          minWidth: 210,
          renderHeader: (h) => {
            return h('div', [
              h('p', '收款方名称'), 
              h('p', '收款方编号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.targetMchName }`),
              h('p', `${ row.targetMchNo }`)
            ])
          }
        },
        {
          key: 'mchOrderNo',
          align: 'center',
          minWidth: 210,
          renderHeader: (h) => {
            return h('div', [
              h('p', '转账订单号'), 
              h('p', '转账流水号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.mchOrderNo }`),
              h('p', `${ row.plfOrderNo }`)
            ])
          }
        },
        {
          key: 'transferAmount',
          align: 'center',
          minWidth: 120,
          renderHeader: (h) => {
            return h('div', [
              h('p', '转账金额(元)'), 
              h('p', '转账手续费(元)'), 
            ])
          },
          slot: 'transferAmountMoney',
        },
        {
          title: '总金额(元)',
          key: 'transferDebitAmount',
          align: 'center',
          minWidth: 120,
          slot:'transferDebitAmount'
        },
        {
          title: '订单状态',
          key: 'orderStatus',
          align: 'center',
          minWidth: 70,
          slot:"orderStatus",
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          minWidth: 90,
          slot: 'action'
        }
      ],
      orderColumns1:  [
        {
          key: 'createTime',
          align: 'center',
          minWidth: 170,
          renderHeader: (h) => {
            return h('div', [
              h('p', '创建时间'), 
              h('p', '交易时间'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.createTime }`),
              h('p', `${ row.tradeTime }`)
            ])
          }
        },
        {
          key: 'sourceMchName',
          align: 'center',
          minWidth: 210,
          renderHeader: (h) => {
            return h('div', [
              h('p', '发起方名称'), 
              h('p', '发起方编号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.sourceMchName }`),
              h('p', `${ row.sourceMchNo }`)
            ])
          }
        },
        {
          key: 'mchOrderNo',
          align: 'center',
          minWidth: 120,
          renderHeader: (h) => {
            return h('div', [
              h('p', '批次转账订单号'), 
              h('p', '批次转账流水号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.mchOrderNo }`),
              h('p', `${ row.plfOrderNo }`)
            ])
          }
        },
        {
          key: 'transferAmount',
          align: 'center',
          minWidth: 100,
          renderHeader: (h) => {
            return h('div', [
              h('p', '转账金额(元)'), 
              h('p', '转账手续费(元)'), 
            ])
          },
          slot: 'transferAmountMoney',
        },
        {
          title: '总金额(元)',
          key: 'transferDebitAmount',
          align: 'center',
          minWidth: 70,
          slot:'transferDebitAmount'
        },
        {
          title: '订单状态',
          key: 'orderStatus',
          align: 'center',
          minWidth: 70,
          slot:"orderStatus",
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          minWidth: 70,
          slot: 'action'
        }
      ],
      orderData: [],
      tabActive: 0, // 0 单笔 1 批量
      tableApi: "",
      carryOverShow:false, //明细展示
      carryOverData:[],
      carryOverColumns: [
        {
          key: 'mch_order_no',
          align: 'center',
          minWidth: 140,
          renderHeader: (h) => {
            return h('div', [
              h('p', '商户订单号'), 
              h('p', '转账流水号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.mch_order_no }`),
              h('p', `${ row.trx_no }`)
            ])
          }
        },
        { title: '转账接收方编号', key: 'target_mch_no',align: 'center',minWidth: 100,},
        { title: '转账金额', key: 'transfer_amount',align: 'center',minWidth: 70,slot:"transfer_amount"},
        { title: '实际扣款金额', key: 'transfer_debit_amount',align: 'center',minWidth: 70,slot:"transfer_debit_amount"},
        { title: '转账手续费', key: 'transfer_fee',align: 'center',minWidth: 70,slot:"transfer_fee"},
        { title: '转账备注', key: 'transfer_remark',align: 'center',minWidth: 100,},
      ],
    }
  },
  computed: {
    ...mapState({
      transferDetail: "trading_refund_zz",
    }),
  },
  created () {
    if (this.transferDetail && this.transferDetail.formData) {
      this.filterData = this.transferDetail.formData;
      this.tabActive = this.transferDetail.tabActive;
    }

    if(this.filterData.orderNoType == "mchOrderNo" || this.filterData.orderNoType == "plfOrderNo" || this.filterData.orderNoType == "upsOrderNo"){
        this.tabActive = 0
        this.tableApi = getTransferorderSearch
    }else{
        this.tabActive = 1
        this.tableApi = getTransferBatchOrderSearch
    }


    if(this.filterData.orderNo !== ""){
      this.getDataByFilter()
    }
  },
  methods: {
    ...mapMutations({
      setTransferZz: "trading_setTransfer_zz",
    }),
    // 请求前钩子
    beforeRequest (params) {
      params.orderNo = params.orderNo.trim()
      delete params.pageNo
      delete params.pageSize
      return params
    },
    afterResponse (list, data) {
      this.orderData = data
      this.totalNum = data.totalNum || 0
      return list
    },
    getDataByFilterBtn(){
      let params = {
        orderNoType: this.filterData.orderNoType,
        orderNo: this.filterData.orderNo.trim(),
      };
      if (!params.orderNo) {
        this.$Message.error(
          "请输入" + this.orderNoType[this.filterData.orderNoType]
        );
        return;
      }

      if(this.filterData.orderNoType == "mchOrderNo" || this.filterData.orderNoType == "plfOrderNo" || this.filterData.orderNoType == "upsOrderNo"){
          this.tabActive = 0
          this.tableApi = getTransferorderSearch
      }else{
          this.tabActive = 1
          this.tableApi = getTransferBatchOrderSearch
      }
      
      this.getDataByFilter()
    },
    // 详情
    toDetail(value) {
      this.$router.push({
        name: "transfer_detail",
        query: {
          id: value.id,
          tabActive:this.tabActive,          
        },
      });
    },
    // 同步
    sync(value) {
      if( this.tabActive == "0"){
        setTransferSync(value.id).then((res) => {
          if (res) {
            this.$Message.success("同步请求成功");
          }
        });
      }else{
        setTransferBatchSync(value.id).then((res) => {
          if (res) {
            this.$Message.success("同步请求成功");
          }
        });
      }

    },
    // 明细
    carryOverBtn(value){
      this.carryOverShow = true;
      this.carryOverData = value;
      console.log(this.carryOverData , "carryOverData");
    },
  },
  beforeDestroy() {
    let data = {
      query: this.transferDetail ? this.transferDetail.query : null,
      formData: this.filterData,
      transferTabActive:this.transferDetail ? this.transferDetail.tabActive : null,
    };
    this.setTransferZz(data);
  },
}
</script>
<style scoped>
.tab1{
  min-height: 650px !important;
}
</style>

