<template>
  <ContainerList
    ref="container"
    :page-size="filterData.pageSize"
    :page-current.sync="filterData.pageNo"
    :page-count="totalNum"
    @page-change="changePageNo"
    @page-size-change="changePageSize">
    <div slot="header">
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>分账方</i>
            <Input v-model="filterData.keyword" suffix="ios-search" size="large" maxlength="128" placeholder="分账方名称/编号" />
          </div>
          <!-- <div class="item">
            <i>所属商户</i>
            <Select v-model="filterData.mchId" size="large" placeholder="选择所属商户">
              <Option v-for="item in mchIdOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </div>  -->
          <div class="item">
            <i>订单状态</i>
            <Select v-model="filterData.orderStatus" size="large" placeholder="选择订单状态">
              <Option v-for="item in orderStatusOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>时间类型</i>
            <Select v-model="filterData.timeType" size="large" placeholder="创建时间" style="width: 130px;">
              <Option v-for="item in timeTypeOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <span style="padding: 0 10px"></span>
            <DatePicker
              v-model="filterData.startTime"
              type="datetime"
              :clearable="false"
              :editable="false"
              :options="startDatePicker"
              placeholder="选择时间"
              style=" width:calc((100% - 130px - 70px - 44.67px - 10px) / 2) !important;"
              format="yyyy-MM-dd HH:mm:ss"
            >
            </DatePicker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <DatePicker
              v-model="filterData.endTime"
              type="datetime"
              :clearable="false"
              :editable="false"
              :options="endDatePicker"
              placeholder="选择时间"
              style=" width:calc((100% - 130px - 70px - 44.67px - 10px) / 2) !important;"
              format="yyyy-MM-dd HH:mm:ss"
              @on-change="endTimeChange"
            >
          </DatePicker>            
          </div>
          <div class="itembtn">
            <Button class="searchBt" @click="getDataByFilter" style="margin-left:20px;">查询</Button>
            <button v-auth="'BASIC:BALANCE:TRANSFER/EXPORT'" class="outputBt" @click="outputShow" style="margin-left:20px;">导出</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 中间交易账目 -->
    <div class="jiaoyi">
      <div class="jiaoyi_box">
        <p><i>转账笔数（笔）</i></p>
        <p><b>{{ transferNum }}</b></p>
      </div>
      <div class="jiaoyi_box">
        <p><i>转账金额（元）</i></p>
        <p><b>{{ (transferAmount / 100) | formatMoney }}</b></p>
      </div>
      <div class="jiaoyi_box">
        <p><i>手续费（元）</i></p>
        <p><b>{{ (transferFee / 100) | formatMoney }}</b></p>
      </div>
      <div class="jiaoyi_box">
        <p><i>总金额（元）</i></p>
        <p><b>{{ (transferDebitAmount / 100) | formatMoney }}</b></p>
      </div>
    </div>
    <!-- 切换 -->
    <div class="header_switch"> 
      <div :class="{ right: true, active: tabActive == 1 ? true : false }" @click="setTabActive(1)">批次转账</div>
      <div :class="{ right: true, active: tabActive == 0 ? true : false }" @click="setTabActive(0)">单笔转账</div>
    </div>
    <Table row-key
      min-width="1000px"
      class="tab1"
      :columns="tabActive == 0 ? tableColumns : tableColumns1"
      :data="tableData"
      :loading="isLoading">
      <template slot="transferAmountMoney" slot-scope="{ row }">
        <p>{{ (row.transferAmount / 100) | formatMoney }}</p>
        <p>{{(row.transferFee / 100) | formatMoney}}</p>
      </template>
      <template slot="transferDebitAmount" slot-scope="{ row }">
        <p>{{ (row.transferDebitAmount / 100) | formatMoney }}</p>
      </template>
      <template slot="orderStatus" slot-scope="{ row }">
        <p>{{ orderStatus[row.orderStatus] }}</p>
      </template>
      <template slot="action" slot-scope="{ row }">
        <a v-auth="'BASIC:BALANCE:TRANSFER/DETAIL'" class="lianjie" @click="toDetail(row)">详情</a>
        <a v-auth="'BASIC:BALANCE:TRANSFER/SYNC'" class="lianjie" @click="sync(row)">同步</a>
        <a v-if="tabActive == 1" v-auth="'BASIC:BALANCE:TRANSFER/MINUTIA'" class="lianjie" @click="carryOverBtn(row)">明细</a>
      </template>
    </Table>
    <!-- 导出弹窗 --> 
    <Modal v-model="outPayShow" title="导出转账记录" closable @on-cancel="outPayShow = false" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_main">
            <div class="overAccount_ul_title"><i>*</i>交易日期</div>
            <Select v-model="selectTimeType" size="large" placeholder="选择交易日期" @on-change="timeChange">
              <Option v-for="item in selectTimeTypeOptions" :value="item.value" :key="item.value" >{{ item.label }}</Option>
            </Select>
          </li>
          <li class="ul_left">
            <div class="overAccount_ul_title" v-show="selectTimeType == -1">
              <i>*</i>开始日期
            </div>
            <DatePicker
              :options="startOption"
              v-model="outputForm.startDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择时间"
              v-show="selectTimeType == -1"
              :disabled="selectTimeType != -1"
            >
            </DatePicker>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title" v-show="selectTimeType == -1">
              <i>*</i>结束日期
            </div>
            <DatePicker
              :options="endOption"
              v-model="outputForm.endDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择时间"
              v-show="selectTimeType == -1"
              :disabled="selectTimeType != -1"
            >
            </DatePicker>
          </li>
          <li class="ul_main" style="clear: both;"></li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <Button class="exitBt" @click="outPayShow = false">取 消</Button>
          <Button class="addBt" @click="output">确 定</Button>
        </span>
    </Modal>
    <!-- 明细 -->
    <Modal title="转账明细" v-model="carryOverShow" closable width="980px" :close-on-click-modal="false">
        <div style="min-height:auto;padding: 0px 20px;box-sizing: border-box;">
          <Table row-key
            :columns="carryOverColumns"
            :data="carryOverData.batchTransferDetailList"
            :loading="isLoading">
            <template slot="transfer_amount" slot-scope="{ row }">
              <p>{{ (row.transfer_amount / 100) | formatMoney }}</p>
            </template>
            <template slot="transfer_debit_amount" slot-scope="{ row }">
              <p>{{ (row.transfer_debit_amount / 100) | formatMoney }}</p>
            </template>
            <template slot="transfer_fee" slot-scope="{ row }">
              <p>{{ (row.transfer_fee / 100) | formatMoney }}</p>
            </template>
          </Table>
        </div>
        <span slot="footer" class="dialog-footer"></span>
    </Modal>
  </ContainerList>  
</template>
<script>
import { formatDate , FormatTime } from "@/utils/common.js";
import {
  getTransferorder,
  getTransferorderSearch,
  exportTransferorder,
  setTransferSync,
  setTransferNotify,
  getTransferBatchOrder,
  setTransferBatchSync,
  setTransfeBatchNotify,
  getTransferBatchOrderSearch,
} from "@/api/basic/balance.js";
import { URL_API } from "@/utils/apiUrl.js";
import { mapState, mapMutations } from "vuex";
import ContainerList from '@/components/ContainerList/index'
import tableMixin from '@/mixins/tableMixin'
export default {
  components: { ContainerList },
  mixins: [ tableMixin ],
  data() {
    let that = this;
    return {
      orderStatusOptions:[
        { label: "全部", value: -1 },
        { label: "处理中", value: "PROCESSING" },
        { label: "交易成功",value: "SUCCESS" },
        { label: "交易失败",value: "FAIL" },
      ],
      orderStatus: {
        PROCESSING: "处理中",
        FAIL: "交易失败",
        SUCCESS: "交易成功",
      },
      timeTypeOptions:[
        { label: "创建时间", value: "CREATE" },
        { label: "交易时间",value: "TRADE" }
      ],
      mchIdOptions:[],
      
      filterData: {
        startTime: formatDate(+new Date(), "yyyy/MM/dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy/MM/dd 23:59:59"),
        pageNo: 1,
        pageSize: 10,
        timeType: "CREATE",
        mchId: "",
        keyword: "",
        orderStatus: -1,
      },

      totalNum: 0,
      transferNum: 0,
      transferAmount: 0,
      transferDebitAmount: 0,
      transferFee: 0,

      outputUrl: URL_API.outputUrl,
      outPayShow: false, //导出展示
      outputForm: {}, //导出信息
      selectTimeType: "", //导出时间类型
      startDatePicker: this.startDate(),
      endDatePicker: this.endData(),

      tabActive: 0, // 0 单笔 1 批量
      carryOverShow:false, //明细展示
      carryOverData:[],
      carryOverColumns: [
        {
          key: 'mch_order_no',
          align: 'center',
          minWidth: 140,
          renderHeader: (h) => {
            return h('div', [
              h('p', '商户订单号'), 
              h('p', '转账流水号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.mch_order_no }`),
              h('p', `${ row.trx_no }`)
            ])
          }
        },
        { title: '转账接收方编号', key: 'target_mch_no',align: 'center',minWidth: 100,},
        { title: '转账金额', key: 'transfer_amount',align: 'center',minWidth: 70,slot:"transfer_amount"},
        { title: '实际扣款金额', key: 'transfer_debit_amount',align: 'center',minWidth: 70,slot:"transfer_debit_amount"},
        { title: '转账手续费', key: 'transfer_fee',align: 'center',minWidth: 70,slot:"transfer_fee"},
        { title: '转账备注', key: 'transfer_remark',align: 'center',minWidth: 100,},
      ],
      tableApi: getTransferorder,
      tableKey: 'orders',
      isFilterKey: true, // 是否开启参数转换
      emptyValue: -1,
      
      totalNum: 0,
      tableLoading: false,
      columns: [],
      tableColumns:  [
        {
          key: 'createTime',
          align: 'center',
          minWidth: 170,
          renderHeader: (h) => {
            return h('div', [
              h('p', '创建时间'), 
              h('p', '交易时间'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.createTime }`),
              h('p', `${ row.tradeTime }`)
            ])
          }
        },
        {
          key: 'sourceMchName',
          align: 'center',
          minWidth: 210,
          renderHeader: (h) => {
            return h('div', [
              h('p', '发起方名称'), 
              h('p', '发起方编号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.sourceMchName }`),
              h('p', `${ row.sourceMchNo }`)
            ])
          }
        },
        {
          key: 'targetMchModel',
          align: 'center',
          minWidth: 210,
          renderHeader: (h) => {
            return h('div', [
              h('p', '收款方名称'), 
              h('p', '收款方编号'), 
            ])
          },
          render(h, { row }) {
            return h('div', [
              h('p', `${ row.targetMchName }`),
              h('p', `${ row.targetMchNo }`)
            ])
          }
        },
        {
          key: 'mchOrderNo',
          align: 'center',
          minWidth: 210,
          renderHeader: (h) => {
            return h('div', [
              h('p', '转账订单号'), 
              h('p', '转账流水号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.mchOrderNo }`),
              h('p', `${ row.plfOrderNo }`)
            ])
          }
        },
        {
          key: 'transferAmount',
          align: 'center',
          minWidth: 100,
          renderHeader: (h) => {
            return h('div', [
              h('p', '转账金额(元)'), 
              h('p', '转账手续费(元)'), 
            ])
          },
          slot: 'transferAmountMoney',
        },
        {
          title: '总金额(元)',
          key: 'transferDebitAmount',
          align: 'center',
          minWidth: 70,
          slot:'transferDebitAmount'
        },
        {
          title: '订单状态',
          key: 'orderStatus',
          align: 'center',
          minWidth: 70,
          slot:"orderStatus",
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          minWidth: 120,
          slot: 'action'
        }
      ],
      tableColumns1:  [
        {
          key: 'createTime',
          align: 'center',
          minWidth: 120,
          renderHeader: (h) => {
            return h('div', [
              h('p', '创建时间'), 
              h('p', '交易时间'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.createTime }`),
              h('p', `${ row.tradeTime }`)
            ])
          }
        },
        {
          key: 'sourceMchName',
          align: 'center',
          minWidth: 100,
          renderHeader: (h) => {
            return h('div', [
              h('p', '发起方名称'), 
              h('p', '发起方编号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.sourceMchName }`),
              h('p', `${ row.sourceMchNo }`)
            ])
          }
        },
        {
          key: 'mchOrderNo',
          align: 'center',
          minWidth: 120,
          renderHeader: (h) => {
            return h('div', [
              h('p', '批次转账订单号'), 
              h('p', '批次转账流水号'), 
            ])
          },
          render(h, { row }) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', `${ row.mchOrderNo }`),
              h('p', `${ row.plfOrderNo }`)
            ])
          }
        },
        {
          key: 'transferAmount',
          align: 'center',
          minWidth: 100,
          renderHeader: (h) => {
            return h('div', [
              h('p', '转账金额(元)'), 
              h('p', '转账手续费(元)'), 
            ])
          },
          slot: 'transferAmountMoney',
        },
        {
          title: '总金额(元)',
          key: 'transferDebitAmount',
          align: 'center',
          minWidth: 70,
          slot:'transferDebitAmount'
        },
        {
          title: '订单状态',
          key: 'orderStatus',
          align: 'center',
          minWidth: 70,
          slot:"orderStatus",
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          minWidth: 70,
          slot: 'action'
        }
      ],
      tableData: [],
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
              new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      startOption:{
        disabledDate(date) {
          return (
            date.getTime() >
              new Date(that.outputForm.endDate) + 24 * 60 * 60 * 1000 ||
            date.getTime() <
              new Date(
                +new Date(that.outputForm.endDate) - 31 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      selectTimeTypeOptions:[
        {label: "今天", value: "1",},
        {label: "昨天",value: "2",},
        {label: "近7天", value: "7",},
        {label: "其它",value: "-1",},
      ],
    };
  },
  computed: {
    ...mapState({
      transferDetail: "trading_refund_zz",
    }),
  },
  created() {
    if (this.transferDetail && this.transferDetail.query) {
      this.filterData = this.transferDetail.query
    }

    this.getDataByFilter()

    this.columns = Object.assign([], this.tableColumns)
    if (this.tabActive == 0) {
      this.tableColumns = this.columns
    } else {
      this.tableColumns = this.columns.filter(item => item.key !== 'targetMchModel')
    }
  },
  methods: {
    ...mapMutations({
      setTransferZz: "trading_setTransfer_zz",
    }),
    // 请求前钩子
    beforeRequest (params) {
      params.keyword = params.keyword.trim();
      params.startTime = FormatTime('yyyy-MM-dd hh:mm:ss',params.startTime); 
      params.endTime = FormatTime('yyyy-MM-dd hh:mm:ss', params.endTime); 
      return params
    },
    afterResponse (list, data) {
      this.totalNum = data.totalNum || 0
      this.transferNum = data.transferNum || 0
      this.transferAmount = data.transferAmount || 0
      this.transferDebitAmount = data.transferDebitAmount || 0
      this.transferFee = data.transferFee || 0
      return list
    },
    startDate() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.endTime
            ? !(
                new Date(vm.filterData.endTime).getTime() >= time.getTime() &&
                time.getTime() >
                  new Date(vm.filterData.endTime).getTime() -
                    31 * 24 * 60 * 60 * 1000
              )
            : true;
        },
      };
    },
    endData() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.startTime
            ? !(
                new Date(vm.filterData.startTime).getTime() <= time.getTime() &&
                time.getTime() <
                  new Date(vm.filterData.startTime).getTime() +
                    31 * 24 * 60 * 60 * 1000
              )
            : true;
        },
      };
    },
    //创建时间改变时
    endTimeChange(time,type){
      if(type == 'date' && time.slice(-8) === '00:00:00'){
        this.filterData.endTime = time.slice(0,-8) + '23:59:59'
      }
    },
    // 导出展示
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        startDate: "",
        endDate: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.startDate = "";
          this.outputForm.endDate = "";
          break;
      }
    },
    // 导出
    output() {
      if (!this.outputForm.startDate || !this.outputForm.endDate) {
        this.$Message.error("导出时间段必选");
        return;
      }
      if (this.outputForm.startDate > this.outputForm.endDate) {
        return this.$Message.error("结束时间不能小于开始时间");
      }
      this.outputForm.startDate = formatDate(+new Date(this.outputForm.startDate), "yyyy-MM-dd")
      this.outputForm.endDate = formatDate(+new Date(this.outputForm.endDate), "yyyy-MM-dd")
      let data = this.outputForm;
      exportTransferorder(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "转账查询.csv");
          document.body.appendChild(link);
          link.click();
        }
        this.outPayShow = false;
      });
    },
    // 详情
    toDetail(value) {
      this.$router.push({
        name: "transfer_detail",
        query: {
          id: value.id,
          tabActive:this.tabActive,          
        },
      });
    },
    // 明细
    carryOverBtn(value){
      this.carryOverShow = true;
      this.carryOverData = value;
      console.log(this.carryOverData , "carryOverData");
    },
    // 同步
    sync(value) {
      if( this.tabActive == "0"){
        setTransferSync(value.id).then((res) => {
          if (res) {
            this.$Message.success("同步请求成功");
          }
        });
      }else{
        setTransferBatchSync(value.id).then((res) => {
          if (res) {
            this.$Message.success("同步请求成功");
          }
        });
      }

    },

    // 通知
    setNotify(value) {
      if( this.tabActive == "0"){
        setTransferNotify(value.id).then((res) => {
          if (res) {
            this.$Message.success("通知请求成功");
          }
        });
      }else{
        setTransfeBatchNotify(value.id).then((res) => {
          if (res) {
            this.$Message.success("通知请求成功");
          }
        });
      }
    },

    //切换表格
    setTabActive(e) {
      this.tabActive = e;
      this.filterData.pageNo = 1
      this.filterData.pageSize = 10
      if (this.tabActive == 0) {
        this.tableApi = getTransferorder
        this.tableColumns = this.columns
      } else {
        this.tableApi = getTransferBatchOrder
        this.tableColumns = this.columns.filter(item => item.key !== 'targetMchModel')
      }
      this.getDataByFilter()
    }
  },
  beforeDestroy() {
    let data = {
      query: this.filterData,
      formData: this.transferDetail ? this.transferDetail.formData : null,
      tabActive:this.tabActive,
    };
    console.log(data,"-------this.tabActive")
    this.setTransferZz(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime {
    width: 63.5%;
}
.tab1{
  min-height: 650px !important;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border: 1px solid var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}
.overAccount_ul {
  padding: 0px 20px;
  box-sizing: border-box;
}
.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}
.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}
.ul_main {
  width: 100%;
  margin: 0 auto 7px;
}
.ul_left {
  float: left;
  width: 200px;
}
.ul-right {
  float: right;
  width: 200px;
}
</style>
