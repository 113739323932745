<template>
  <div id="mian">
    <Tabs v-model="crumbs">
      <TabPane label="批量查询" name="batch"></TabPane>
      <TabPane label="订单查询" name="order"></TabPane>
    </Tabs>
    <!-- 批量订单查询 -->
    <div v-if="crumbs === 'batch'">
      <BatchList></BatchList>
    </div>
    <div v-if="crumbs === 'order'">
      <OrderList></OrderList>
    </div>
  </div>
</template>
<script>
import BatchList from './components/batch_list'
import OrderList from './components/order_list'
export default {
  components: { BatchList, OrderList },
  data () {
    return {
      crumbs: 'batch'
    } 
  },
  created () {
  },
  methods: {}
};
</script>
<style scoped>

.con_from .lineP .itemTime{
  width: 63.4%;
}
.con_from .lineP .item i {
  width: 64px;
}

.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 64px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 64px - 130px - 44.67px - 10px) / 2);
}

.tab1{
    min-height: 650px !important;
}

</style>
